<template>
  <div class="searchMapView" id="searchMap"></div>
</template>

<script>

let selectImg = require('../../../assets/icons/dingwei-phone-s.png')
let unselectImg = require('../../../assets/icons/dingwei-phone-un.png')

import { createModule } from '@/assets/JS/moduleJs'

export default {
  name: 'searchMapView',
  props: {
    deviceDetail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    deviceDetail: {
      handler(value) {
        this.getMapDetailById()
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      module: createModule(),
      buildingId: '',
      floorList: [],
      mapData: {},
      mapId: ''
    }
  },
  mounted() {
    localStorage.setItem('titleScale', 3)
    this.$nextTick(() => {
      const div = document.getElementById('searchMap')
      this.module.createMap('searchMap', div.offsetWidth, div.offsetHeight, 0) // 先初始化地图参数
    })
  },
  beforeDestroy() {
    this.module.realseMap()
  },
  methods: {
    getMapDetailById: function() {
      if (typeof this.deviceDetail['mapId'] == 'undefined' || this.deviceDetail['mapId'] == null) {
        this.module.deleteMapGroup()
        this.$message.warning('该资产没有定位位置')
        return
      }
      let mapId = this.deviceDetail['mapId']
      if (this.mapId == mapId) {
        this.showPoint()
        return
      }
      this.mapId = ''
      this.module.deleteMapGroup()
      let mapDetail = () => {
        return new Promise(resolve => {
          let id = this.deviceDetail['mapId']
          this.$api.userInfo.getMapDetail({ id: id }).then(res => {
            if (res.code != 200) {
              resolve({ result: false, msg: '无法查询资产所在地图' })
              return
            }
            let mapList = []

            try {
              let json = JSON.parse(res.data.json)
              mapList.push(json)
            } catch (e) {

            }

            if (mapList.length == 0) {
              resolve({ result: false, msg: '无法查询资产所在地图' })
              return
            }
            resolve({ result: true, mapList: mapList })
          }).catch(e => {
            resolve({ result: false, msg: 'net error' })
            return
          })
        })
      }

      mapDetail().then(res => {
        if (res.result == false) {
          this.$message.warning(res.msg)
          return
        }
        this.showMap(res.mapList, () => {

        })
      })

    },
    showMap: function(mapInfos, callBack) {
      this.module.updateMapDataInfo(mapInfos, { lazyLoad: true }, list => {
        this.floorList = list
        if (list.length == 0) {
          return
        }
        this.showPoint()
        callBack()

      })
    },
    clearPoint: function() {
      this.module.updatePointsInfo({
        point: []
      })
    },
    showPoint: function() {

      const info = this.floorList[this.floorList.length - 1]
      let mapId = info.mapid
      this.mapId = mapId
      this.module.showMap(mapId)

      let xIsExist = true
      if (typeof this.deviceDetail['x'] == 'undefined' || this.deviceDetail['x'] == null) {
        xIsExist = false
      }

      let zIsExist = true
      if (typeof this.deviceDetail['z'] == 'undefined' || this.deviceDetail['z'] == null) {
        zIsExist = false
      }

      if (xIsExist == false || zIsExist == false) {
        this.$message({
          type: 'warning',
          message: '该资产没有定位位置'
        })
        return
      }

      let x = parseFloat(this.deviceDetail['x'])
      let z = parseFloat(this.deviceDetail['z'])

      let point = {
        mapid: mapId + '',
        x: x,
        y: 0,
        z: z,
        type: 'point',
        unSelect: unselectImg,
        select: selectImg,
        centerY: 0.1414
      }
      let map = this.module.getMap()
      let pointManager = map.getPoint()
      pointManager.updatePointsInfoAndFindPoint({ point: [point] }, point, {
        animation: '',
        hiddenExtra: ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.searchMapView {
  width: 100%;
  height: 100%;
  background-color: #EBFEFA;
}

</style>
