<template>
  <div class="searchDeviceDetail">
    <div class="searchDeviceMap">
      <search-map-view :device-detail="deviceDetail"></search-map-view>
    </div>
    <div class="searchDetail" v-if="type==1">
      <ul class="searchUl">
        <li class="searchLi">
          <cell_title_detail head-title="资产名称" :content-title="deviceDetail.name"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="资产编号" :content-title="deviceDetail.code"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="资产品牌" :content-title="deviceDetail.brandName"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="资产类型" :content-title="deviceDetail.categoryName"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="资产型号" :content-title="deviceDetail.modelName"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="科室" :content-title="deviceDetail.tenantName"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="标签" :content-title="deviceDetail.label"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="更新时间" :content-title="formatDate(deviceDetail.updateTime)"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="资产位置" :content-title="deviceDetail.place"></cell_title_detail>
        </li>
<!--        <li class="searchLi" @click="traval">-->
<!--          <cell_title_detail head-title="设备轨迹" ></cell_title_detail>-->
<!--        </li>-->
      </ul>
    </div>
    <div class="searchDetail" v-if="type==5">
      <ul class="searchUl">
        <li class="searchLi">
          <cell_title_detail head-title="科室" :content-title="deviceDetail.tenantName"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="标签" :content-title="deviceDetail.label"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="时间" :content-title="formatDate(deviceDetail.startTs)"></cell_title_detail>
        </li>
        <li class="searchLi">
          <cell_title_detail head-title="设备位置" :content-title="deviceDetail.address"></cell_title_detail>
        </li>
        <!--        <li class="searchLi" @click="traval">-->
        <!--          <cell_title_detail head-title="设备轨迹" ></cell_title_detail>-->
        <!--        </li>-->
      </ul>
    </div>

    <patient-traval ref="traval"></patient-traval>
  </div>
</template>

<script>
import Cell_title_detail from '@/components/cell/cell_title_detail/cell_title_detail'
import SearchMapView from '@/views/searchDevice/searchMapView/searchMapView'
import PatientTraval from '@/views/alertMessge/traval/patientTraval'
export default {
  name: 'searchDeviceDetail',
  components: { PatientTraval, SearchMapView, Cell_title_detail },
  props: {
    type: {
      default: 1
    },
    deviceDetail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods:{
    traval:function() {
      if (typeof this.$refs['traval'] == 'undefined' || this.$refs['traval'] == null) {
        return
      }
      this.$refs['traval'].showOrHidden(true, this.deviceDetail);
    },
  }
}
</script>

<style lang="scss" scoped>

.searchDeviceDetail {
  width: 100%;
  height: 100%;

  .searchDeviceMap {
    width: 100%;
    height: 30%;
  }

  .searchDetail {
    width: 100%;
    margin-top: 10px;
    height: calc(70% - 10px);
    overflow: scroll;

    .searchUl {
      padding: 0;
      list-style-type: none;

      .searchLi {

      }

    }

  }
}


</style>
