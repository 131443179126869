<template>
  <div class="handle_panel" :class="'handle_panel_' + showType">

    <div class="tabbar_panel">
      <ul ref="trajectoryList" class="trajectory_list"
          :style="{ width: trajectoryList.length * itemWidth + 'px', 'margin-left': axisMarginLeft }">
        <li v-for="(item, index) in trajectoryList" :key="index"
            :class="{ active: (total > 1 && index === playIndex) || total == 1 }" @click="setIndex(index)">
          <span v-if="showType == 'timestamp'">{{ formatDate(item.timestamp) }}</span>
          <template v-else>
            <span>{{ formatDate(item.startTime) }}</span>
            <span>-</span>
            <span>{{ formatDate(item.endTime) }}</span>
          </template>
        </li>
      </ul>

      <div class="slider_panel">
        <div class="center_panel center_bar">
          <slider v-model="playIndex" :min="0" :max="total > 1 ? total - 1 : total" :format-tooltip="formatTooltip"
                  @change="sliderChange" @startClick="startClick"
          />
        </div>
      </div>

      <div class="playView">
        <button class="back" @click="backEvent">
          <i class="el-icon-caret-left"></i>
        </button>
        <button class="play" @click="playEvent">
          <i :class="{'el-icon-video-pause':isPlaying, 'el-icon-video-play':!isPlaying}"></i>
        </button>
        <button class="go" @click="goEvent">
          <i class="el-icon-caret-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import slider from './slider/main.vue'
import SvgIcon from '@/components/SvgIcon'

export default {
  name: 'TrajectoryHandlePanel',
  components: { SvgIcon, slider },
  props: {
    showType: {
      type: String,
      default: 'timestamp' // times- 时间段, timestamp- 单个时间
    },
    play: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 0
    },
    trajectoryList: {
      type: Array,
      default: () => {
        return []
      }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      playIndex: 0,
      itemWidth: 128,
      isplay: false,
      isStartClick: false,
      speedOptions: [
        {
          label: '0.1s',
          value: 100
        },
        {
          label: '0.3s',
          value: 300
        },
        {
          label: '0.5s',
          value: 500
        },
        {
          label: '0.8s',
          value: 800
        },
        {
          label: '1.0s',
          value: 1000
        },
        {
          label: '1.2s',
          value: 1200
        }
      ]
    }
  },
  computed: {
    isPlaying() {
      return this.play && this.total && this.total - 1 !== this.playIndex;
    },
    axisMarginLeft() {
      const wrapWidth = this.$refs.trajectoryListWrap ? this.$refs.trajectoryListWrap.clientWidth : 0
      const trajectoryListWidth = this.trajectoryList.length * this.itemWidth
      if (this.playIndex > 1) {
        let MR = (this.playIndex - 1) * this.itemWidth
        if (MR + wrapWidth > trajectoryListWidth) {
          MR = trajectoryListWidth - wrapWidth
        }
        return '-' + MR + 'px'
      } else {
        return 0
      }
    },
    speedLabel() {
      for (let i = 0; i < this.speedOptions.length; i++) {
        const item = this.speedOptions[i]
        if (item.value === this.speed) {
          return item.label
        }
      }
      return ''
    }
  },
  watch: {
    index: {
      handler() {
        this.playIndex = this.index
      },
      immediate: true
    },
    total: {
      handler() {
        if (this.total < 2) this.playIndex = this.total
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {

    formatTooltip(value) {
      return value + 1
    },
    playEvent() {
      this.$emit('update:play', !this.play)
      this.$emit('play', !this.play)
    },
    backEvent() {
      if (this.playIndex >= this.total || this.playIndex < 0) {
        return;
      }
      let newIndex = this.playIndex - 1;
      if (newIndex < 0) {
        newIndex = 0;
      }
      this.setIndex(newIndex)
    },
    goEvent() {
      if (this.playIndex >= this.total || this.playIndex < 0) {
        return;
      }
      let newIndex = this.playIndex + 1;
      if (newIndex >= this.total) {
        newIndex = this.total - 1
      }
      this.setIndex(newIndex)
    },
    setIndex(index) {
      this.$set(this, 'playIndex', index)
      this.$emit('update:index', index)
      this.changeEvent(index)
    },
    speedEvent(item) {
      this.$emit('update:speed', item.value)
      this.$emit('speedChange', item.value)
    },
    sliderChange(e) {
      this.$set(this, 'playIndex', e)
      this.$emit('update:index', e)
      this.changeEvent(e)
    },
    startClick() {
      this.isStartClick = true
      if (this.play) {
        this.isplay = true
        this.$emit('update:play', false)
        this.$emit('play', false)
      }
    },
    changeEvent(index) {
      let _isplay = 0
      if (this.isplay && this.isStartClick) {
        _isplay = this.isplay ? 1 : 0
      } else {
        _isplay = this.play ? 1 : 0
      }
      this.$emit('change', index, _isplay)
      this.isplay = false
      this.isStartClick = false
    }
  }
}
</script>


<style lang="scss" scoped>

.handle_panel {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 160px;
  padding: 8px 0;
  background: rgba(0, 0, 0, 0.1);


  .center_panel {
    width: 90%;
    transform: translate(5.555%, 0px);
  }

  .slider_panel {
    height: 38px;
    display: flex;
    align-items: center;

    .center_panel {
      ::v-deep .el-slider__runway,
      .el-slider__runway {
        background: #9fa0a0;
      }
    }

  }

  .playView {
    display: flex;
    justify-content: center;

    button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      color: #5fb0ed;
      border-radius: 50%;
      border: 0px;
    }

    .play {
      margin: 0 10px;
    }
  }

  .tabbar_panel {

    margin-top: 4px;
    width: 100%;
    height: 120px;

    .trajectory_list {
      margin: 0px;
      padding: 0px;
      height: 100%;
      user-select: none;
      transition: margin-left 0.06s linear;
      display: flex;
      height: 70px;

      li {
        flex-shrink: 0;
        display: inline-block;
        width: 120px;
        height: 60px;
        border: 1px solid #409EFF;
        margin: 0px 4px;
        border-radius: 4px;
        overflow: hidden;
        line-height: 1;

        &:hover {
          background: #409EFF;
        }

        &.active {
          background: #409EFF;

          span {
            color: #fff;
          }
        }

        span {
          height: 20px;
          line-height: 20px;
          width: 100%;
          display: inline-block;
          font-size: 12px;
          color: #9fa0a0;
          text-align: center;
          cursor: pointer;
        }
      }
    }

  }
}

.handle_panel_times {
  .trajectory_list {
    li {
      line-height: 12px !important;

      span {
        line-height: 14px !important;
      }
    }
  }
}
</style>
<style lang="scss">

.play_speed_popper {
  min-width: 0;
  padding: 4px;

  .play_speed_list {
    li {
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      text-align: center;

      &:hover {
        color: #409EFF;
        font-weight: 700;
      }

      &.active {
        background: #cccccc;
        color: #409EFF;
        font-weight: 700;
      }
    }
  }
}

.trajectoryList::-webkit-scrollbar {
  width: 0 !important;
}
</style>
