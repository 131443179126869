<template>
  <el-dialog
      class="travalView"
      :title="patientInfo.name"
      top="10px"
      :visible.sync="hidden"
      width="calc(95vw)"
  >
    <div class="block">
      <div class="title">开始时间</div>
      <el-date-picker
          @change="change"
          class="timeStamp"
          size="mini"
          type="datetime"
          v-model="startTime"
          placeholder="选择日期时间">
      </el-date-picker>
    </div>
    <div class="block">
      <div class="title">结束时间</div>
      <el-date-picker
          @change="change"
          v-model="endTime"
          class="timeStamp"
          size="mini"
          type="datetime"
          placeholder="选择日期时间">
      </el-date-picker>
    </div>
    <div class="map_wrap" id="map_wrap">
      <div v-show="showWran&&!loading" class="wranMsg">
        <div class="content">
          {{ noTravlMsg }}
        </div>
      </div>

      <trajectoryHandlePanel
          v-show="!showWran&&!loading"
          :trajectory-list="trajectoryList"
          :total="total"
          :play.sync="play"
          :index.sync="playIndex"
          :speed.sync="speed"
          show-type="times"
          @play="playEvent"
          @change="playIndexChange"
          @speedChange="playSpeedChange"
      />
    </div>
  </el-dialog>

</template>

<script>
import { Loading } from 'element-ui'
import { createModule } from '@/assets/JS/moduleJs'
import trajectoryHandlePanel from '../traval/trajectoryHandlePanel/index'
import { mapTraval } from '@/views/alertMessge/traval/trajectory/mapTraval'

export default {
  name: 'patientTraval',
  components: { trajectoryHandlePanel },
  mixins: [mapTraval],
  props: {
    baseData: {
      type: Object,
      default: () => {
        return {
          createTime: '',
          startTime: '',
          endTime: ''
        }
      }
    },
    requestTime: {
      default: 12 * 60 * 60 * 1000
    },
    viewType: {
      default: '1'
    },
    visible: {
      default: true
    }
  },
  computed: {
    title() {

    },
    meetingPointList() {
      let judge = (index, list) => {
        if (index >= list.length) {
          return { result: false }
        }
        let item = list[index]
        if (typeof item == 'undefined' || item == null) {
          return { result: false }
        }
        if (typeof item['list'] == 'undefined' || item['list'] == null) {
          return { result: false }
        }
        return { result: true }
      }

      let res1 = judge(this.selectMeeting, this.meetingList)
      if (res1.result == false) {
        return []
      }
      let item = this.meetingList[this.selectMeeting]
      let itemList = item['list']
      let res2 = judge(this.selectMeetingSub, itemList)
      if (res2.result == false) {
        return []
      }
      return itemList[this.selectMeetingSub].list
    }

  },
  watch: {
    patientInfo: {
      handler(value) {}
    },
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      }
    },
    hidden: {
      handler(value) {
        if (value) {
          if (this.load) {
            this.showTravalMap()
            return
          }
          this.initMap('map_wrap', () => {
            this.showTravalMap()
          })
          return
        }
        this.$emit('cancelTraval', { visible: value })
        this.module.deleteMapGroup()
        this.showWran = false
      }
    },
    visible: {
      handler(value) {
        this.hidden = value
      }
    }
  },
  data() {
    return {
      startTime:'',
      endTime:'',
      patientInfo: {},
      selectMeeting: 0,
      selectMeetingSub: 0,
      meetingList: [], // 与患者相遇的数组
      load: false,
      module: createModule(),
      buildingId: '',
      tenantId: '',
      loadingView: null,
      loading: false,
      hidden: false,
      timesTamp: [],
      showWran: false
    }
  },
  mounted() {
    this.noTravlMsg = '在所选时间段内该设备没有移动'
    this.showWranMsg = () => {
      this.showWran = true
    }
  },
  methods: {
    change:function() {
      this.loading = true
      let loadTravl = () => {
        Promise.all([this.getTraval()]).then(res => {
          this.updateTravlDataWithTimeQuantum(res[0], (result) => {
            if (result.result == false) {
              this.loading = false
              this.showWran = true
              return
            }
            this.loading = false
            this.showWran = false
          })
        })
      }
      loadTravl();
    },
    showOrHidden: function(show, item) {
      this.hidden = show
      this.patientInfo = item
    },
    getTimeMsg: function(item) {
      if (typeof item['list'] == 'undefined' || item['list'] == null) {
        return ''
      }
      let itemList = item['list']
      if (itemList.length == 0) {
        return ''
      }
      let start = itemList[0]
      if (typeof start['timestamp'] == 'undefined' || start['timestamp'] == null) {
        return ''
      }
      return new Date(parseInt(start.timestamp)).toLocaleString().replace(/:\d{1,2}$/, ' ')
    },
    getTimeToNow: function(timestamp) {
      if (typeof timestamp == 'undefined' || timestamp == null) {
        return ''
      }
      let time = timestamp / 1000
      let fixed = 0
      if (time < 0) {
        return ''
      } else if (time < 60) {
        return time.toFixed(fixed) + '秒'
      } else if (time >= 60 && time < 60 * 60) {
        return (time / 60).toFixed(fixed) + '分钟'
      } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
        return (time / (60 * 60)).toFixed(fixed) + '小时'
      } else if (time >= 60 * 60 * 24 && time < 60 * 60 * 24 * 30) {
        return (time / (60 * 60 * 24)).toFixed(fixed) + '天'
      } else if (time >= 60 * 60 * 24 * 30 && time < 60 * 60 * 24 * 30 * 12) {
        return (time / (60 * 60 * 24 * 30)).toFixed(fixed) + '月'
      } else if (time >= 60 * 60 * 24 * 30 * 12 && time < 60 * 60 * 24 * 30 * 12 * 100) {
        return (time / (60 * 60 * 24 * 30 * 12)).toFixed(fixed) + '年'
      } else {
        return '很久很久'
      }
    },
    cancelMeetingInfo: function() {
      for (let i = 0; i < this.meetingPointList.length; i++) {
        let info = this.meetingPointList[i]
        if (typeof this.$refs[info.divId] == 'undefined') {
          continue
        }
        let list = this.$refs[info.divId]
        for (let j = 0; j < list.length; j++) {
          list[j].showOrHidden(false)
        }
      }
    },
    showTravalMap: function() {
      this.meetingList = []
      this.trajectoryList = []
      this.total = 0
      this.selectMeeting = 0
      this.selectMeetingSub = 0
      this.showWran = false
      if (typeof this.patientInfo['id'] == 'undefined' || this.patientInfo['id'] == null || this.patientInfo['id'] == '') {
        this.showWran = true
        return
      }
      this.loading = true

      let loadTravl = () => {
        Promise.all([this.getTraval()]).then(res => {
          this.updateTravlDataWithTimeQuantum(res[0], (result) => {
            if (result.result == false) {
              this.loading = false
              this.showWran = true
              return
            }
            this.loading = false
            this.showWran = false
          })
        })
      }

      let oneDay = 24 * 60 * 60 * 1000
      let startTime = this.patientInfo['startTs'] - oneDay * 0.1

      let time = this.patientInfo['startTs'] + oneDay * 0.1
      let nowTime = new Date().getTime()
      let endTime = time >= nowTime ? nowTime : time
      this.startTime = new Date(startTime)
      this.endTime = new Date(endTime)
      loadTravl()
    },
    startLoading: function() {
      this.$nextTick(() => {
        const dom = document.getElementById('map_wrap')
        if (typeof dom == 'undefined' || dom == null) {
          return
        }
        this.loadingView = Loading.service({ target: dom, background: '#ffffff66' })
      })
    },

    getTraval: function() {
      return new Promise(resolve => {
        let startTime = this.startTime.getTime()
        let end = this.endTime.getTime()
        this.$api.assetModule
            .getDeviceTrajectory({
              deviceId: this.patientInfo.deviceId,
              startTime: startTime,
              endTime: end,
              limit:20,
            })
            .then(res => {
              resolve({ result: true, list: res.data })
            })
            .catch(() => {
              resolve({ result: false, list: [] })
            })
      })
    },
    getLocationMapDetail: function(mapid) {
      return new Promise(resolve => {
        this.loading = true
        this.$api.userInfo
            .getMapDetail({
              id: mapid
            })
            .then(res => {
              this.loading = false
              const json = res.data.json
              const zoneInfoStr = res.data.zoneInfo
              try {
                const mapInfo = JSON.parse(json)
                const zoneInfo = JSON.parse(zoneInfoStr)
                mapInfo['zoneInfo'] = zoneInfo
                mapInfo['extraInfo'] = []
                resolve({ result: true, mapList: [mapInfo] })
              } catch (res) {
                resolve({ result: true, mapList: [] })
              }
            })
            .catch(() => {
              this.loading = false
              resolve({ result: false })
            })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.travalView {

  .block {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    height: 28px;

    .title {
      width: 80px;
      line-height: 28px;
      text-align: left;
    }

    .timeStamp {
      width: calc(100% - 80px);
    }

  }

  .map_wrap {
    width: 100%;
    height: calc(70vh);
    position: relative;
    overflow: hidden;
    border: 1px solid #cccccc;
    background-color: #eae8e8;
  }

  .travalMap {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .wranMsg {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      color: #a1a1a1;
    }

  }

  .meetingList {
    width: 200px;
    height: calc(100% - 104px);
    left: 10px;
    top: 0px;
    position: absolute;
    overflow: scroll;

    .selectItem {
      border: 1px solid #409EFF;
    }

    .unSelectItem {
      border: 1px solid #a1a1a1;
    }

    .titleContent {
      width: 90%;
      height: 125px;
      margin-top: 10px;

      .label {
        width: 70px;
        height: 25px;
        text-align: right;
        font-size: 13px;
        float: left;
        line-height: 25px;
      }

      .value {
        font-size: 13px;
        height: 25px;
        width: calc(100% - 70px);
        float: right;
        line-height: 25px;
      }

    }


    .info_list {
      width: 90%;
      margin-top: 5px;
      transform: translate(5.555%, 0px);

      .info_item {
        //display: flex;
        //align-items: flex-start;
        margin-bottom: 4px;
        line-height: 18px;
        font-size: 14px;
        color: #9fa0a0;
        border-radius: 5px;
        padding: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          width: 50px;
          text-align: right;
          padding-right: 4px;
          font-weight: 700;
          color: #9fa0a0;
          font-size: 10px;
        }

        .value {
          width: calc(100% - 40px);
          font-size: 10px;
        }
      }
    }

  }


}

.travalView::v-deep .el-dialog__body {
  padding: 0px 20px 20px 20px;
}

.meetingList::-webkit-scrollbar {
  width: 0 !important;
}


.meetingList::v-deep .el-collapse-item {
  border-radius: 5px;
  border: 1px solid #a1a1a1;
  margin-top: 5px;
}

.meetingList::v-deep .el-collapse-item__header {
  height: 145px;
  background-color: rgba(0, 0, 0, 0);
}

.meetingList::v-deep .el-collapse-item__wrap {
  background-color: rgba(0, 0, 0, 0);
}


::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}

::v-deep .travalView .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}

</style>

<style lang="scss">

</style>
