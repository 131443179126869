const selectImg = require('../../../../../src/assets/icons/dingwei-phone-s.png')
export const mapTraval = {
  data() {
    return {
      noTravlMsg: '暂无轨迹',
      mapInfo: {
        mapId: '',
        mapListInfo: {}
      },
      mapList: [],
      trajectoryList: [],
      newTrajectoryList: [],
      total: 0,
      play: false,
      playIndex: 0,
      speed: 500,
      pathInfo: {},
    }
  },
  methods: {

    initMap: function (divId, callBack) {
      this.$nextTick(() => {
        const div = document.getElementById(divId)
        if (div == null) {
          callBack({result: false});
          return;
        }
        this.module.createMap(divId, div.offsetWidth, div.offsetHeight, 0) // 先初始化地图参数
        this.module.setMapConfig([{type: 'controller', config: {zoomSize: true}}])
        let map = this.module.getMap();
        let travl = map.getTravl();
        travl.indexChange((res) => {
          let index = res.index;
          if (this.playIndex == index) {
            return;
          }
          // 播放回调下标
          this.shouldChangeMap(index, this.play)
        })
        travl.stopTravl(() => {
          this.play = false
        })
        this.load = true;
        setTimeout(() => {
          this.module.forceToResize()
          callBack({result: true});
        }, 500)
      })
    },
    shouldChangeMap: function (index, play) {
      if (index >= this.trajectoryList.length) {
        return
      }
      let map = this.module.getMap();
      let travl = map.getTravl();

      const item = this.trajectoryList[index]
      if (this.mapInfo.mapId == item.mapId) {
        this.playIndex = index;
        return
      }

      this.getNewMapAndFindWay({mapId: item.mapId, reset: true, travalList: this.trajectoryList, index: index}, () => {
        this.playIndex = index;
        travl.startAnimation({speed: this.speed, index: index})
      })
    },

    updateTravl: function (index, travalList, newTrajectoryList, callBack) {
      let info = travalList[index];
      const mapid = info.mapId
      this.module.showMap(mapid)
      this.mapInfo.mapId = mapid

      this.newTrajectoryList = newTrajectoryList;
      this.trajectoryList = travalList
      this.total = travalList.length

      let map = this.module.getMap();
      let travl = map.getTravl();
      travl.updateTravlData({
        drawTravlLine: travalList,
        moveTravlLine: newTrajectoryList,
        mapId: mapid,
        drawTravlLineConfig: {
          show: false
        },
        moveTravlLineConfig: {
          color: 0x0000ff,
          y: 2.5,
          polygonOffset: true,
          transparent: false,
          depthTest: true,
          show: true,
          lineStyle: 'dash',
        },
        index: index,
        markConfig: {
          image: selectImg,
          showRemarkTitle: true,
          userCenter: 0.1414,
          remarkCenter: -2,
          style: 'one',
        },
      })
      setTimeout(() => {
        callBack();
      }, 500)
    },
    getNewMapAndFindWay: function (config, callBack) {

      const updateTrajectoryList = (list, index, trajectoryList, callBack) => {

        if (list.length <= 1) {
          callBack(list)
          return
        }
        if (index >= list.length - 1) {
          callBack(trajectoryList)
          return
        }
        const p = list[index]
        const nextP = list[index + 1]
        this.dealWithTravalList(p, nextP, path => {
          let newList = trajectoryList.concat(path);
          updateTrajectoryList(list, index + 1, newList, callBack)
        })
      }


      let mapId = config['mapId'];
      let reset = config['reset'];
      let travalList = config['travalList']
      let index = config['index']
      let noMap = () => {
        this.showWranMsg();
        if (reset) {
          this.reset();
        }
      }

      this.getLocationMapDetail(mapId).then(mapRes => {
        if (mapRes.result == false) {
          noMap();
          this.module.deleteMapGroup()
          return
        }
        this.updateMapInfo(mapRes, (res) => {
          if (res.result == false) {
            noMap();
            this.module.deleteMapGroup()
            return;
          }

          updateTrajectoryList(travalList, 0, [], newTrajectoryList => {
            this.updateTravl(index, travalList, newTrajectoryList, () => {
              callBack();
            })
          })
        })
      })
    },


    dealWithTravalList: function (startPoint, endPoint, callBack) {
      function getTimesamp(startPoint, endPoint, list) {
        const timeCount = endPoint['startTime'] - startPoint['endTime']
        const time = timeCount / (list.length == 0 ? 1 : list.length) / 2.0
        for (let i = 0; i < list.length; i++) {
          list[i]['startTime'] = startPoint['endTime'] + time * (i + 1)
          list[i]['endTime'] = list[i]['startTime'] + time
          list[i]['timestamp'] = list[i]['startTime'] + time
          if (i == list.length - 1) {
            list[i]['index'] = endPoint['index'];
          } else {
            list[i]['index'] = startPoint['index'];
          }
        }
      }

      if (startPoint.mapId != endPoint.mapId) {
        callBack([startPoint, endPoint])
        return
      }
      if (startPoint.x == endPoint.x && startPoint.z == endPoint.z) {
        callBack([startPoint, endPoint])
        return
      }
      const key = startPoint.x + '~' + startPoint.z + '~' + startPoint.mapId + '~' + endPoint.x + '~' + endPoint.z + '~' + endPoint.mapId
      let map = this.module.getMap();
      let user = map.getUser()
      user.findLeadWay(startPoint, startPoint.mapId, endPoint, endPoint.mapId, 1, res => {
        let list = []
        if (res.result == false || res.isLink == false) {
          list = [startPoint, endPoint]
          getTimesamp(startPoint, endPoint, list)
          this.pathInfo[key] = list
          callBack(this.pathInfo[key])
          return
        }
        const indexs = res['indexs']
        for (let i = 0; i < res.mapOrder.length; i++) {
          const order = res.mapOrder[i]
          if (typeof indexs[order.mapid + ''] == 'undefined') {
            continue
          }
          const index = order['index']
          const paths = indexs[order.mapid + '']
          if (index >= paths.length) {
            continue
          }
          const pathInfo = paths[index]
          const p = pathInfo.path
          for (let j = p.length - 1; j >= 0; j--) {
            const point = p[j]
            const info = {
              mapId: order.mapid + '',
              place: '',
              tenantId: startPoint.tenantId,
              timestamp: 0,
              x: point.x,
              z: point.z
            }
            list.push(info)
          }
        }
        this.pathInfo[key] = JSON.parse(JSON.stringify(list))
        getTimesamp(startPoint, endPoint, list)
        callBack(list)
      })
    },
    updateMapInfo: function (dataInfo, callBack) {
      this.module.deleteMapGroup()
      const mapInfos = dataInfo.mapList
      this.module.updateMapDataInfo(mapInfos, {lazyLoad: false}, list => {
        this.mapList = list
        if (list.length == 0) {
          callBack({result: false})
          return
        }
        setTimeout(() => {
          callBack({result: true})
        }, 200)
      })
    },

    reset: function () {
      this.play = false
      this.playIndex = 0
      this.total = 0
      this.newTrajectoryList = [];
      this.trajectoryList = []
      let map = this.module.getMap();
      let travl = map.getTravl();
      travl.stopAnimation({})
      travl.updateTravlData({
        drawTravlLine: [],
        moveTravlLine: [],
        mapId: this.mapInfo.mapId,
        drawTravlLineConfig: {
          show: false
        },
        moveTravlLineConfig: {
          color: 0x0000ff,
          y: 2.5,
          polygonOffset: true,
          transparent: false,
          depthTest: true,
          show: true,
          lineStyle: 'dash',
        },
        index: 0,
        markConfig: {
          image: selectImg,
          showRemarkTitle: true,
          userCenter: 0.1414,
          remarkCenter: -2,
          style: 'one',
        },
      })
    },
    showWranMsg: function () {
      this.$message.warning(this.noTravlMsg)
    },
    updateTravlDataWithTimeQuantum: function (res, callBack) {
      let isValue = (info, key) => {
        if (typeof info == 'undefined' || info == null) {
          return false
        }
        if (typeof info[key] == 'undefined' || info[key] == null) {
          return false
        }
        return true
      }

      const list = res.list
      const newList = []

      for (let i = 0; i < list.length; i++) {
        const info = list[i]
        if (!isValue(info, 'x') && !isValue(info, 'z') && !isValue(info, 'mapId')) continue

        if (typeof info['startTime'] != 'undefined' && typeof info['startTime'] != null) {
          info['timestamp'] = info['startTime'];
        } else if (typeof info['timestamp'] != 'undefined' && typeof info['timestamp'] != null) {
          info['startTime'] = info['timestamp']
          info['endTime'] = info['timestamp']
        }
        info['index'] = newList.length; // 添加索引index
        newList.push(info)
      }

      if (newList.length == 0) {
        this.showWranMsg();
        this.reset();
        this.module.deleteMapGroup()
        callBack({result: false})
        return
      }

      const location = newList[0]
      const mapId = location.mapId
      this.getNewMapAndFindWay({mapId: mapId, reset: true, travalList: newList, index: 0,}, () => {
        callBack({result: true})
      })
    },

    playSpeedChange() {
      let map = this.module.getMap();
      let travl = map.getTravl();
      travl.stopAnimation({})
      setTimeout(() => {
        travl.startAnimation({speed: this.speed})
        this.play = true
      }, 500)
    },

    playIndexChange(index, isPlay) {
      this.playEvent(false)
      this.play = false;

      let map = this.module.getMap();
      let travl = map.getTravl();

      let playAction = (index, isPlay, mapId) => {
        this.playIndex = index
        travl.selectIndex({index: index, mapId: mapId})
      }

      let judgeMap = (index, play) => {
        if (index >= this.trajectoryList.length) {
          return
        }
        const item = this.trajectoryList[index]
        if (this.mapInfo.mapId == item.mapId) {
          playAction(index, play)
          return
        }
        this.getNewMapAndFindWay({
          mapId: item.mapId,
          reset: true,
          travalList: this.trajectoryList,
          index: index
        }, () => {
          playAction(index, play)
        })
      }

      judgeMap(index, isPlay);
    },

    playEvent(e) {
      // 是否播放
      let map = this.module.getMap();
      let travl = map.getTravl();
      if (e) {
        travl.startAnimation({speed: this.speed})
      } else {
        travl.stopAnimation({})
      }
    },

  },
  beforeDestroy() {
    this.reset();
    this.module.realseMap_forceContextLoss()
  },
}
